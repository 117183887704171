@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playpen+Sans:wght@500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden !important;
}

.logo {
    font-family: 'Playpen Sans', cursive;
    color: #057e23;
    font-weight: bold;

}

.hero-bg {
    /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/public/images/jute1.jpg); */
    /* background-color: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)); */
    object-fit: cover;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    white-space: nowrap;
    transition: ease 1000ms;
}

.top-shap-hero {
    top: 3.70rem;
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
}

.bottom-shap-hero {
    transform: rotate(180deg);
    bottom: -50px;
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
}

.sub-hero-text {
    font-size: 20px;
    font-weight: inherit;
    color: #525252;
    text-align: center;
}

.sub-hero-text-2nd {
    font-size: 24px;
    font-weight: bold;
    color: #525252;
    text-align: center;

}

.story-text-h1 {
    font-family: 'Playfair Display', serif;
    color: #a4d852;
    font-size: 60px;
    font-style: italic;
    font-weight: 800;
}

.site-text-h1 {
    font-family: 'Playfair Display', serif;
    font-size: 40px;
    font-style: italic;
    font-weight: 800;
}

.button {
    /* font-family: "Montserrat", Sans-serif; */
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2px;
    fill: #fff;
    color: #fff;
    background-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-color: #fff;
    border-radius: 50px;
    padding: 18px 36px;
    transition: .5s;
    font-weight: 700;
}

.button:hover {
    background-color: #a4d852;
    border: transparent 2px solid;
    color: #12373a;
}

.hero-welcome-p {
    color: #fff;
    font-family: "Playfair Display", Sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 7px;
}

.hero-heading-h1 {
    color: #fff;
    font-family: "Playfair Display", Sans-serif;
    font-weight: 300;
}

.hero-bottom-text {
    color: #fff;
    font-family: "Playfair Display", Sans-serif;
    text-transform: capitalize;
    letter-spacing: 0;
    font-weight: 400;
}


.product-heading {
    color: #54595f;
    font-family: "Playfair Display", Sans-serif;
    font-size: 79px;
    line-height: 1em;
    text-shadow: 0 0 0 rgba(0, 0, 0, .3);
    font-style: italic;
    font-weight: 800;
    text-align: center;
}
.product-heading-sub-heading {
    color: #54595f;
    font-size: 55px;
    line-height: 1.3em;
    text-shadow: 0 0 0 rgba(0, 0, 0, .3);
    font-style: italic;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
    text-wrap: wrap;
    margin-top: 20px;
}
.contact-heading {
    color: #54595f;
    font-family: "Playfair Display", Sans-serif;
    font-size: 79px;
    line-height: 1em;
    text-shadow: 0 0 0 rgba(0, 0, 0, .3);
    font-style: italic;
    font-weight: 800;
    text-align: center;
}

.m-product-heading {
    color: #a4d852;
    font-family: "Playfair Display", Sans-serif;
    font-size: 79px;
    line-height: 1em;
    text-shadow: 0 0 0 rgba(0, 0, 0, .3);
    font-style: italic;
    font-weight: 800;
    text-align: center;
}


.button-no-2 {
    width: 130px;
    padding: 13px 15px;
    font-family: "Noto Sans", Sans-serif;
    font-size: 11px;
    font-weight: 400;
    border-radius: 4px;
    color: #fff;
    background-color: #494949;
    cursor: pointer;
}

.link-background {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/public/images/background-support-cta-scaled.jpg);
    /* background-color: gray; */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 700px;
}

.vessel-background {
    background: linear-gradient(0deg, rgba(54, 168, 235, 0.3), rgba(54, 168, 235, 0.3)), url(/public/images/shipping.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 500px;
}

.react-photo-gallery--gallery>div>img {
    border-radius: 25px;
    border: 8px solid transparent;
    cursor: pointer;
    transition: transform .4s;
}

.react-photo-gallery--gallery>div>img:hover {
    border-radius: 12px;
    transform: scale(1.1);
}


.button-plane {
    /* font-family: "Montserrat", Sans-serif; */
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2px;
    fill: #12373a;
    color: #12373a;
    background-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-color: #12373a;
    border-radius: 50px;
    padding: 18px 36px;
    transition: .5s;
    font-weight: 700;
}

.button-plane:hover {
    background-color: #a4d852;
    border: transparent 2px solid;
    color: #12373a;
}


.sacrament {
    color: #54595f;
    font-family: "Sacramento", Sans-serif;
    font-size: 48px;
    font-weight: 300;
}

.playfar {
    color: #54595f;
    font-family: "Playfair Display", serif;
    font-size: 70px;
    line-height: 1em;
    text-shadow: 0 0 0 rgba(0, 0, 0, .3);
    font-weight: 800;
    font-style: italic;
}

.gallery-nuni-text {
    color: #242424;
    line-height: 1.3em;
    font-size: 44px;
    font-weight: 300;
}

.vessel-text {
    color: #fff;
    font-family: "Sacramento", Sans-serif;
    font-size: 48px;
    font-weight: 800;
}

.query-text {
    color: #DBC576;
    font-family: "Sacramento", Sans-serif;
    font-size: 48px;
    font-weight: 800;
}


.lint-heading {
    color: #fff;
    font-family: "Playfair Display", Sans-serif;
    font-size: 82px;
    line-height: 1em;
    text-shadow: 0 0 0 rgba(0, 0, 0, .3);
    font-weight: 900;
    font-style: italic;
}

.link-p {
    color: #c5c5c5;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4px;
}


.services-p {
    color: #777;
    font-family: "Noto Sans", Sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    letter-spacing: 0.5px;
    line-height: 28px;
}

.services-h1 {
    margin-bottom: 16px;
    color: #54595f;
    font-family: "Noto Sans", Sans-serif;
    font-size: 1.5em;
    font-weight: 600;
    text-transform: capitalize;
    font-style: normal;
    text-decoration: none;
}

.services-box {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 7px 7px 10px 0 rgba(0,0,0,.19);
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
    margin: 3%;
    --e-column-margin-right: 3%;
    --e-column-margin-left: 3%;
    padding: 1em;
}





@media (max-width: 640px) {
    .heading-h1 {
        text-wrap: balance;
        font-size: 38px;
        margin-left: 20px;
        margin-right: 20px;
        line-height: 55px;
    }
}


.times-new-roman {
    font-family: 'Tinos', serif;
    font-weight: 400;
    font-style: italic;
    font-size: 18px;
    line-height: 35px;
    text-wrap: wrap;
}
.times-new-roman-hessian {
    font-family: 'Tinos', serif;
    font-weight: 400;
    font-style: italic;
    font-size: 22px;
    line-height: 35px;
    text-wrap: wrap;
}